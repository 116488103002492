import create from "zustand";

const useCart = create(
  (set, _) => ({
    notification: null,
    closeNotification: () => set((state) => ({ ...state, notification: null })),
    showNotification: (message) =>
      set((state) => ({ ...state, notification: message })),
  }),
  { name: "ui" }
);
export default useCart;
