import { useState } from "react";
import styles from "./Layout.module.css";
import Offcanvas from "react-bootstrap/Offcanvas";
import Link from "next/link";
// import { faShoppingCart } from '@fortawesome/free-solid-svg-icons'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// import Sidebar from './Sidebar';

const Navbar = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <div>
      <div className="fixed-top" id="navbar">
        {/* <div className={styles.topmostheader}></div> */}
        <nav className={`${styles.navbar} navbar navbar-expand-lg navbar pt-1 pb-1`}>
          <div className="container">
            <Link href="/">
              <a className="navbar-brand">
                <img src="/assets/logo_purple.png" alt='logo_image' className='brand_image'></img>
              </a>
            </Link>
            <div
              className="menu-btn d-lg-none"
              type="button"
              onClick={handleShow}
            >
              <div className="menu-btn__burger"></div>
            </div>
            <div className="collapse navbar-collapse" id="collapsibleNavbar">
              <ul className="ms-auto links mb-0" style={{ '--items': '6' }}>
                <li className="nav-item">
                  <Link className="nav-link" href="/programs">
                    PROGRAMS
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" href="/transformations">
                    STORIES
                  </Link>
                </li>
                <li className="nav-item" id="aaa">
                  <Link className="nav-link" href="/blogs">
                    BLOGS
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" href="/contact">
                    CONTACT
                  </Link>
                </li>
                <li className="nav-item" id="aaa">
                  <Link className="nav-link" href="/tools/bmi">
                    TOOLS
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" href="/about">
                    ABOUT
                  </Link>
                </li>
                <li className="line"></li>
              </ul>
            </div>
          </div>
        </nav>
      </div>

      <div className={styles.bottomNavbar}></div>
      <Offcanvas show={show} onHide={handleClose}>
        <Offcanvas.Body>
          <img
            src="/assets/cross_icon.png"
            className={styles.closeIcon}
            onClick={handleClose}
            alt="CloseIcon"
          />

          <div className={styles.sidebar}>
            <ul className="links">
              <span className="line"></span>
              <li className="nav-item" onClick={handleClose}>
                <img src="/assets/menu/customer.png" alt="programs"></img>
                <Link className="nav-link" href="/programs">
                  PROGRAMS
                </Link>
              </li>
              <li className="nav-item" onClick={handleClose}>
              <img src="/assets/menu/story.png" alt="stories"></img>
                <Link className="nav-link" href="/transformations">
                  STORIES
                </Link>
              </li>
              <li className="nav-item" onClick={handleClose}>
              <img src="/assets/menu/blog.png" alt="blogs"></img>
                <Link className="nav-link" href="/blogs">
                  BLOGS
                </Link>
              </li>
              <li className="nav-item" onClick={handleClose}>
              <img src="/assets/menu/contact.png" alt="contact"></img>
                <Link className="nav-link" href="/contact">
                  CONTACT
                </Link>
              </li>
              <li className="nav-item" onClick={handleClose}>
              <img src="/assets/menu/calculator.png" alt="calculator"></img>
                <Link className="nav-link" href="/tools/macro">
                  TOOLS
                </Link>
              </li>
              <li className="nav-item" onClick={handleClose}>
              <img src="/assets/menu/information.png" alt="information"></img>
                <Link className="nav-link" href="/about">
                  ABOUT
                </Link>
              </li>
            </ul>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
};

export default Navbar;
