import Link from "next/link";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleRight,
  faMagnifyingGlass,
} from "@fortawesome/free-solid-svg-icons";
import {
  faFacebookSquare,
  faInstagram,
  faLinkedin,
  faTwitter,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";

const Footer = () => {
  return (
    <div>
      <footer>
        <div className="row m-0">
          <div className="col-lg-3 d-text-center">
            <img
              src="/assets/logo_white.png"
              className="w-80"
              alt="brand_logo"
            />
            <div className="d-flex w-80 m-auto socialIcons justify-content-between mt-4">
              <a href="https://www.facebook.com/optimumwellnessglobal">
                <FontAwesomeIcon icon={faFacebookSquare} />
              </a>
              <a href="https://twitter.com/Optimum_World">
                <FontAwesomeIcon icon={faTwitter} />
              </a>
              <a href="https://www.youtube.com/channel/UCudbMVyb_71J4sw7BI7XifA">
                <FontAwesomeIcon icon={faYoutube} />
              </a>
              <a href=" https://www.linkedin.com/company/78387094">
                <FontAwesomeIcon icon={faLinkedin} />
              </a>
              <a href="https://www.instagram.com/optimum_wellness_official/">
                <FontAwesomeIcon icon={faInstagram} />
              </a>
            </div>
          </div>
          <div className="col-lg-3 ps-xl-5 mt-4 mt-md-0">
            <div className="fo-22 text-white fw-600">LOCATION</div>
            <div className="fo-14 text-white mt-4">
              77C Dilkhusha Street, Ground Floor, Office 102, Kolkata, West
              Bengal, 700017
            </div>
            <div className="fo-14 text-white mt-4">
              contact.optimumwellness@gmail.com
            </div>
            <div className="fo-14 text-white mt-1">+91 74495 53312</div>
          </div>
          <div className="col-lg-3 mt-4 mt-md-0 ps-xxl-5">
            <div className="fo-22 text-white fw-600">LINKS</div>
            <div className="fo-14 text-white mt-4">
              <Link href="/programs">Programs</Link>
            </div>
            <div className="fo-14 text-white mt-2">
              <Link href="/transformations">Transformations</Link>
            </div>
            <div className="fo-14 text-white mt-2">
              <Link href="/blogs">Blogs</Link>
            </div>
            <div className="fo-14 text-white mt-2">
              <Link href="/about">About</Link>
            </div>
            <div className="fo-14 text-white mt-2">
              <Link href="/contact">Contact</Link>
            </div>
            <div className="fo-14 text-white mt-2">
              <Link href="/TermsAndConditions">Terms and Conditions</Link>
            </div>
            <div className="fo-14 text-white mt-2">
              <Link href="/PrivacyPolicy">Privacy Policy</Link>
            </div>
            <div className="fo-14 text-white mt-2">
              <Link href="/RefundPolicy">Refund Policy</Link>
            </div>
          </div>
          <div className="col-lg-3 mt-4 mt-md-0">
            <div className="fo-22 text-white fw-600">KEEP IN TOUCH</div>
            <div className="fo-14 text-white mt-4">
              Sign up now for our mailing list to get all latest news and
              updates
            </div>
            <div className="d-flex newsletter mt-3">
              <input
                type="text"
                name=""
                placeholder="Your Email Address"
              ></input>
              <div className="fo-14 fw-700">
                <a href="#" className="bco m-auto fw-600 fo-26">
                  <FontAwesomeIcon icon={faAngleRight} />
                </a>
              </div>
            </div>
            <form action="/blogs/search" method="get" className="searchForm">
              <FontAwesomeIcon icon={faMagnifyingGlass} />
              <input
                type="text"
                name="search"
                className="searchBar"
                placeholder="Search..."
              />
            </form>
          </div>
          <div className="col-lg-12 mt-4 mt-md-0 text-center">
            <div className="fo-14 text-white mt-5">
              Optimum Wellness © 2022. All Rights Reserved.
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
