import { Toast, ToastContainer } from "react-bootstrap"
import useCart from '../../store/store';
import styles from './Layout.module.css';

const Notification = () => {
  const message = useCart((state) => state.notification);
  const onClose = useCart((state) => state.closeNotification);
  return (
    <ToastContainer position='bottom-end' containerPosition="fixed" className={styles.toastContainer}>
      <Toast onClose={onClose} show={message !== null} delay={5000} autohide className={`${styles.notification}`}>
        <Toast.Body><div style={{ fontSize: '1.3rem' }}>{message}</div></Toast.Body>
      </Toast>
    </ToastContainer>
  )
}

export default Notification;
