//import AOS from 'aos';
import Head from "next/head";
import Script from "next/script";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { config } from "@fortawesome/fontawesome-svg-core";
import "@fortawesome/fontawesome-svg-core/styles.css";
config.autoAddCss = false;

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

//import "aos/src/sass/aos.scss";
import "@splidejs/react-splide/css/core";
import "../styles/globals.css";
import "bootstrap/dist/css/bootstrap.css";

import Navbar from "../components/layout/Navbar";
import Footer from "../components/layout/Footer";
import Notification from "../components/layout/Notification";
import Link from "next/link";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";

function MyApp({ Component, pageProps }) {
  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="description" content="Jumpstart your fitness journey with Optimum Wellness, where 100+ coaches provide you with customised solutions for every fitness goal. Get sustainable programs that are backed by solid research."  />
        <meta name="keywords" content="fitness, health, wellness" />
        <title>Optimum Wellness | The One Stop Solution for Your Fitness Journey</title>
      </Head>
      <Script
        src="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/js/bootstrap.bundle.min.js"
        integrity="sha384-MrcW6ZMFYlzcLA8Nl+NtUVF0sA7MsXsP1UyJoMp4YLEuNSfAP+JcXn/tWtIaxVXM"
        crossorigin="anonymous"
      />

      <div>
        <input type="checkbox" id="chatClick"></input>
        <label htmlFor="chatClick" className="chatLabel">
          <FontAwesomeIcon icon={faWhatsapp} className="fab" />
          <FontAwesomeIcon icon={faClose} className="fas" />
        </label>
        <div className="wrapper">
          <div className="floatingIcon">
            <a href="https://wa.me/917449553312?text=Hello!%20I%20am%20ready%20to%20start%20my%20fitness%20journey!">
              <img
                src="https://assets.alanvidyushbaptist.com/images/whatsapp.png"
                className="w-100"
                alt="WhatsappIcon"
              ></img>
            </a>
          </div>

          <div className="floatingIcon2">
            <a href="tel:+917449553312">
              <img
                src="https://assets.alanvidyushbaptist.com/images/call.png"
                className="w-100"
                alt="WhatsappIcon"
              ></img>
            </a>
          </div>
        </div>
        <Navbar />
        <main>
          <Component {...pageProps} />
        </main>
        <Notification />
        <Footer />
        <div className="sendButton consultationButton pulsating floatBtn d-sm-none d-flex justify-content-center align-items-start">
          <Link href="/chooseProgram">Start Your Journey!</Link>
        </div>
      </div>
    </>
  );
}

export default MyApp;
